// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage"
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAfyyTFY2Mal6X5Xg--zsd48nFt6cmCKdE",
  authDomain: "portfolio-site-234b0.firebaseapp.com",
  projectId: "portfolio-site-234b0",
  storageBucket: "portfolio-site-234b0.appspot.com",
  messagingSenderId: "494871370376",
  appId: "1:494871370376:web:339de70d5576d0faa41d3d",
  measurementId: "G-ZWCDGK55ZV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const storage = getStorage(app)
export {analytics , storage}

