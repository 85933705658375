import React, { useState } from "react";
import {
  Header,
  Home,
  Footer,
  About,
  Education,
  Experience,
  Services,
  Review,
  Tools,
  // Contact,
} from "../sections";
import { Row, Col, Container } from "react-bootstrap";
import ScrollTopArrow from "./ScrollTopArrow";

import "../components/Icons";
import "bootstrap/dist/css/bootstrap.min.css";
import { getDownloadURL, ref } from "firebase/storage";
import { storage } from "firebase";

function App() {
  const resumeRef = ref(storage, "Ghani_Shahir_Resume_12_27_21.pdf");
  const [resumeUrl, setResumeUrl] = useState("");
  getDownloadURL(resumeRef)
    .then((url) => {
      console.log(url);
      setResumeUrl(url);
    })
    .catch((error) => {
      switch (error.code) {
        case "storage/object-not-found":
          // File doesn't exist
          break;
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          break;
        case "storage/canceled":
          // User canceled the upload
          break;
        case "storage/unknown":
          // Unknown error occurred, inspect the server response
          break;
      }
    });
  return (
    <>
      <Header />
      <main>
        <Home resumeUrl={resumeUrl} />
        <About resumeUrl={resumeUrl} />
        <Container>
          <Row>
            <Col md={6}>
              <Education />
            </Col>
            <Col md={6}>
              <Experience />
            </Col>
          </Row>
        </Container>
        <Services />
        <Review />
        <Tools />
        {/* <Contact /> */}
      </main>
      <Footer />
      <ScrollTopArrow />
    </>
  );
}

export default App;
